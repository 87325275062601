import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { Box, useMediaQuery } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import { useTheme } from "@mui/material/styles"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import GeneralContact from "./GeneralContact"

const ContactPopup = ({
  locale,
  location,
  open,
  handleClose,
  selectedHelperUid = "-",
  selectedTeamMemberUid = "-",
  selectedContactLocationUid = "-",
  additionalData = {},
}) => {
  const theme = useTheme()

  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const [modal, setModal] = useState(open)

  useEffect(() => {
    setModal(open)
  }, [open])

  return (
    <Dialog
      open={modal}
      onClose={handleClose}
      PaperProps={{ square: true }}
      sx={{ zIndex: 99999 }}
      fullScreen={isSmDown}
    >
      <Box
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          cursor: "pointer",
          color: theme => theme.palette.common.white,
        }}
        onClick={handleClose}
        onKeyDown={handleClose}
        role="button"
        tabIndex="0"
      >
        <CloseRoundedIcon />
      </Box>
      <GeneralContact
        sx={{
          paddingTop: 6,
        }}
        location={location}
        locale={locale}
        dense={isSmUp}
        selectedHelperUid={selectedHelperUid}
        selectedTeamMemberUid={selectedTeamMemberUid}
        selectedContactLocationUid={selectedContactLocationUid}
        additionalData={additionalData}
      />
    </Dialog>
  )
}

export default ContactPopup

ContactPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedHelperUid: PropTypes.string,
  selectedTeamMemberUid: PropTypes.string,
  selectedContactLocationUid: PropTypes.string,
  additionalData: PropTypes.object,
}
