import { styled } from "@mui/material/styles"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import clsx from "clsx"
import { Link } from "gatsby"
import React, { useContext, useState } from "react"

import LangContext from "../../contexts/LangContext"
import logoBluePng from "../../img/logo_blue_new.png"
import logoWhitePng from "../../img/logo_white_new.png"

const PREFIX = "Logo"

const classes = {
  logo: `${PREFIX}-logo`,
  logoWrapper: `${PREFIX}-logoWrapper`,
  logoImg: `${PREFIX}-logoImg`,
  logoBlue: `${PREFIX}-logoBlue`,
  logoWhite: `${PREFIX}-logoWhite`,
  onTop: `${PREFIX}-onTop`,
}

const StyledLink = styled(Link)(({ theme }) => ({
  [`&.${classes.logo}`]: {
    display: "block",
    marginTop: 0,
    pointerEvents: "auto",
  },

  [`& .${classes.logoWrapper}`]: {
    position: "relative",
    width: 150,
    transition: "all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1)",

    [theme.breakpoints.down("md")]: {
      height: 50,
    },
  },

  [`& .${classes.logoImg}`]: {
    maxWidth: 150,
    transition: "all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1)",

    [theme.breakpoints.down("md")]: {
      height: 100,
    },
  },

  [`& .${classes.logoBlue}`]: {
    position: "absolute",
    left: 0,
    width: "100%",
    height: "100%",
    background: theme.palette.common.white,
    top: -80,

    [theme.breakpoints.down("md")]: {
      top: -55,
    },
  },

  [`& .${classes.logoWhite}`]: {
    position: "absolute",
    left: 0,
    width: "100%",
    height: 150,
    top: -65,

    [theme.breakpoints.down("md")]: {
      top: -48,
      height: 0,
    },
  },

  [`& .${classes.onTop}`]: {
    top: 0,
  },
}))

const Logo = ({ onClose }) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down("md"))

  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const [showMinimizedLogo, setShowMinimizedHeader] = useState(false)
  const [opacityBlue, setOpacityBlue] = useState(100)
  const [opacityWhite, setOpacityWhite] = useState(0)
  const [top, setTop] = useState(true)

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y < -50
      if (isShow !== showMinimizedLogo) setShowMinimizedHeader(isShow)

      if (currPos.y > -50) {
        setOpacityBlue(100)
        setOpacityWhite(0)
      } else {
        setOpacityBlue(0)
        setOpacityWhite(100)
      }

      if (currPos.y > -50) {
        setTop(true)
      } else {
        setTop(false)
      }
    },
    [showMinimizedLogo],
    null,
    false,
    0,
  )

  return (
    <StyledLink
      to={i18n.default ? `/` : `/${i18n.path}`}
      className={classes.logo}
    >
      <div className={classes.logoWrapper}>
        <div
          className={clsx(classes.logoBlue, top ? classes.onTop : "")}
          style={{ opacity: opacityBlue }}
        >
          <img
            src={logoBluePng}
            alt="MONZA ARES"
            className={clsx(classes.logoImg)}
          />
        </div>
        <div
          className={clsx(classes.logoWhite, top ? classes.onTop : "")}
          style={{ opacity: opacityWhite }}
        >
          <img
            src={logoWhitePng}
            alt="MONZA ARES"
            width={isSmDown ? 100 : 150}
            height={isSmDown ? 100 : 140}
            className={clsx(classes.logoImg)}
          />
        </div>
      </div>
    </StyledLink>
  )
}

export default Logo
