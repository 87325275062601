import { createTheme } from "@mui/material"
import { red } from "@mui/material/colors"

// const breakpoints = createBreakpoints({})

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      "Roboto Condensed",
      "Oxygen",
      '"Helvetica Neue"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Oxygen",
      "Arial",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontWeightRegular: 300,
    fontWeightBold: 400,
    letterSpacing: 1.1,
    fontSize: 18,

    h1: {
      fontSize: {
        xs: "2rem",
        md: "2.73rem",
      },
    },
    h2: {
      fontSize: "1.8rem",
      lineHeight: 1.1,
      margin: "0.83em 0",
      fontWeight: 700,
    },
    h3: {
      fontSize: "5rem",
    },
    subtitle1: {
      lineHeight: 1.1,
      margin: "0.83em 0",
    },
    body1: {
      fontSize: "1rem",
    },
  },
  palette: {
    primary: {
      main: "#065ea6",
    },
    secondary: {
      main: "#f36f27",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    customGreen: {
      main: "#16c163",
    },
    customOrange: {
      main: "#f36f27",
    },
    aresBlue: {
      main: "#065ea6",
    },
  },
})

export default theme
