module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  url: "https://medicina-interventionala.ro", // Domain of your site. No trailing slash!
  logo: "/logos/logoares.png", // Used for SEO
  // JSONLD / Manifest
  favicon: "static/logos/favicon-16x16.png", // Used for manifest favicon generation
  shortName: "MONZA ARES", // shortname for manifest. MUST be shorter than 12 characters
  backgroundColor: "white",
  themeColor: "white",
  facebook: "", // Facebook Site Name
  googleAnalyticsID: "UA-38342881-1",
  title: "Centrele de Excelenta Monza Ares | Inovatie in cardiologie",
}
