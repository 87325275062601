import React, { createContext, useContext, useState } from "react"

import SearchDialog from "../components/search/SearchDialog"

const GlobalSearchContext = createContext()

export const useSearch = () => useContext(GlobalSearchContext)

const GlobalSearchProvider = ({ children }) => {
  const [open, setOpen] = useState(false)

  const openSearch = () => {
    setOpen(true)
  }

  const closeSearch = () => {
    setOpen(false)
  }

  return (
    <GlobalSearchContext.Provider value={{ openSearch, closeSearch }}>
      {open && <SearchDialog onClose={closeSearch} />}
      {children}
    </GlobalSearchContext.Provider>
  )
}

export default GlobalSearchProvider
