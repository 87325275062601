import Button from "@mui/material/Button"
import React from "react"

const CustomButton = props => {
  const { className, children, fullWidth } = props

  return (
    <Button
      sx={{
        boxShadow: "none",
        borderRadius: 0,
        textTransform: "initial",
        fontSize: 14,
        color: "common.white",
        width: "100%",
      }}
      {...props}
      className={className}
    >
      {children}
    </Button>
  )
}

export default CustomButton
