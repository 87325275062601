import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"
import React, { useContext, useState } from "react"

import LangContext from "../../contexts/LangContext"
import LocationContext from "../../contexts/LocationContext"
import CustomButton from "../buttons/CustomButton"
import PhoneIcon from "../icons/PhoneIcon"
import ScheduleIcon from "../icons/ScheduleIcon"
import ContactPopup from "./ContactPopup"

const PREFIX = "ContactBar"

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
}

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: "fixed",
    padding: theme.spacing(1),
    bottom: 0,
    width: "100%",
    zIndex: 1001,
  },

  [`& .${classes.button}`]: {
    fontSize: 20,
    fontWeight: 700,
    borderRadius: 30,
  },
}))

const ContactBar = () => {
  const location = useContext(LocationContext)
  const lang = useContext(LangContext)

  const [open, setOpen] = useState(false)

  const i18n = lang.i18n[lang.locale]

  return (
    <Root className={classes.root}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <CustomButton
            color="secondary"
            variant="contained"
            fullWidth
            className={classes.button}
            component="a"
            href="tel:+40319300"
          >
            <PhoneIcon />
            &nbsp;{i18n.translations.call}
          </CustomButton>
        </Grid>
        <Grid item xs={8}>
          <CustomButton
            color="secondary"
            variant="contained"
            fullWidth
            className={classes.button}
            onClick={() => setOpen(true)}
          >
            <ScheduleIcon />
            &nbsp;Cere o programare
          </CustomButton>
        </Grid>
      </Grid>
      <ContactPopup
        locale={lang.locale}
        location={location}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </Root>
  )
}

export default ContactBar
