import { Box, ListItemButton } from "@mui/material"
import Button from "@mui/material/Button"
import List from "@mui/material/List"
import { Link } from "gatsby"
import React, { useContext, useState } from "react"
import shortid from "shortid"

import LangContext from "../../contexts/LangContext"
import LocationContext from "../../contexts/LocationContext"

const MenuButton = ({ item, showBorder }) => {
  const location = useContext(LocationContext)
  const lang = useContext(LangContext)

  const i18n = lang.i18n[lang.locale]

  const [open, setOpen] = useState(false)

  const handleMouseEnter = () => {
    setOpen(true)
  }

  const handleMouseLeave = () => {
    setOpen(false)
  }

  return (
    <Box
      sx={{
        position: "relative",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        size="small"
        variant="text"
        sx={{
          minWidth: 0,
          padding: 1,
          color: theme => theme.palette.common.black,
          flexShrink: 0,
          fontWeight: 300,
          fontSize: 14,
          marginBottom: 1,
          marginTop: 1,
          "&:hover": {
            backgroundColor: "transparent",
            color: theme => theme.palette.aresBlue.main,
          },
        }}
        to={
          i18n.default ? `/${item.internal}` : `/${i18n.path}/${item.internal}`
        }
        component={Link}
      >
        {item.label}
      </Button>
      {open && (
        <List
          sx={{
            padding: 0,
            background: theme => theme.palette.common.white,
            position: "absolute",
            top: 40,
            left: 0,
            boxShadow: showBorder ? "0px 2px 5px -5px #000" : "none",
          }}
          component="nav"
          aria-label="secondary mailbox folders"
        >
          {item.sublinks.map(subitem => (
            <ListItemButton
              key={shortid.generate()}
              sx={{
                minWidth: 0,
                padding: 1 / 2,
                flexShrink: 0,
                fontWeight: 300,
                fontSize: 14,
                whiteSpace: "nowrap",

                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme => theme.palette.aresBlue.main,
                },
              }}
            >
              <Button
                size="small"
                variant="text"
                to={
                  i18n.default
                    ? `/${subitem.internal}`
                    : `/${i18n.path}/${subitem.internal}`
                }
                sx={{
                  color: theme => theme.palette.common.black,
                  minWidth: 0,
                  padding: 1 / 2,
                  flexShrink: 0,
                  fontWeight: 300,
                  fontSize: 14,
                  whiteSpace: "nowrap",

                  "&:hover": {
                    backgroundColor: "transparent",
                    color: theme => theme.palette.aresBlue.main,
                  },

                  ...(location.pathname.match(subitem.internal) && {
                    color: theme => theme.palette.aresBlue.main,
                    fontWeight: 600,
                    position: "relative",
                    "&:after": {
                      content: '" "',
                      height: 3,
                      width: "100%",
                      position: "absolute",
                      bottom: -4,
                      zIndex: 1000,
                    },
                  }),
                }}
                component={Link}
              >
                {subitem.label}
              </Button>
            </ListItemButton>
          ))}
        </List>
      )}
    </Box>
  )
}

export default MenuButton
