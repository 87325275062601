import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"
import React from "react"

const StyledTextField = styled(TextField)({
  "&.MuiTextField-root": {
    backgroundColor: "#fcfcfb",
    borderRadius: 0,
    fontSize: 16,
  },
  "& .MuiOutlinedInput-input": {},
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: 0,
  },
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },

  "& .MuiInputLabel-root": {
    fontSize: 16,
    backgroundColor: "#fff",
    padding: "0 10px",
    marginLeft: "-5px",
  },
})

export default function CustomTextField(props) {
  const inputProps = {}

  if (props.pattern) {
    inputProps.pattern = props.pattern
  }

  return (
    <StyledTextField
      size="small"
      variant="outlined"
      margin="dense"
      {...props}
    />
  )
}
