// This HEADER is different and used only on the Homepage
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Collapse from "@mui/material/Collapse"
import { default as MaterialLink } from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import { styled } from "@mui/material/styles"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import shortid from "shortid"

import LangContext from "../../contexts/LangContext"

const PREFIX = "SubMenuItem"

const classes = {
  list: `${PREFIX}-list`,
  item: `${PREFIX}-item`,
  link: `${PREFIX}-link`,
  sublink: `${PREFIX}-sublink`,
}

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.list}`]: {
    background: "#ffffff",
    listStyle: "none",
    padding: 0,
    margin: 0,
    maxHeight: "calc(100vh - 100px)",
    "overflow-y": "scroll",
  },

  [`& .${classes.item}`]: {
    "&:first-of-type": {
      borderTop: "1px solid #013754",
    },
    color: theme.palette.aresBlue.main,
    borderBottom: "1px solid #013754",
  },

  [`& .${classes.link}`]: {
    display: "block",
    pointerEvents: "auto",
    lineHeight: "1.2em",

    color: "#fff",
    fontSize: 22,
    textDecoration: "none",
    fontWeight: 400,
    "&:hover": {
      textDecoration: "none",
    },
  },

  [`& .${classes.sublink}`]: {
    display: "block",
    pointerEvents: "auto",
    lineHeight: "1.2em",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    color: "#fff",
    fontSize: 22,
    fontWeight: 400,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))

const SubMenuItem = ({ item, toggleMobileMenu }) => {
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const [open, setOpen] = useState(false)

  const toggleSubmenu = () => {
    setOpen(!open)
  }

  return (
    <Root>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <ListItem button onClick={toggleSubmenu}>
          <ListItemText
            primary={
              <MaterialLink className={classes.link} onClick={toggleSubmenu}>
                {item.label}
              </MaterialLink>
            }
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.sublinks.map(sublink => (
              <ListItem key={shortid.generate()} button>
                <ListItemText
                  primary={
                    <Link
                      to={
                        i18n.default
                          ? `/${sublink.internal}`
                          : `/${i18n.path}/${sublink.internal}/`
                      }
                      className={classes.sublink}
                      onClick={toggleMobileMenu}
                    >
                      {sublink.label}
                    </Link>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    </Root>
  )
}

export default SubMenuItem

SubMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
}
