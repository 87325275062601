import FacebookIcon from "@mui/icons-material/Facebook"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import YouTubeIcon from "@mui/icons-material/YouTube"
import { Box } from "@mui/material"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Hidden from "@mui/material/Hidden"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useContext } from "react"
import * as shortid from "shortid"

import LangContext from "../../contexts/LangContext"
import logoAresGroup from "../../img/logo_monza_ares_group.png"
import logoSal from "../../img/logo_sal.webp"
import logoSol from "../../img/logo_sol.webp"
import ContactBar from "../contacts/ContactBar"

const PREFIX = "Footer"

const classes = {
  root: `${PREFIX}-root`,
  socialButtons: `${PREFIX}-socialButtons`,
  socialButton: `${PREFIX}-socialButton`,
  disclaimer: `${PREFIX}-disclaimer`,
  menu: `${PREFIX}-menu`,
  menuItem: `${PREFIX}-menuItem`,
  menuLinks: `${PREFIX}-menuLinks`,
  logo: `${PREFIX}-logo`,
}

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    marginTop: theme.spacing(4),
    background: "#58595b",
    paddingBottom: 30,
    paddingTop: 30,

    [theme.breakpoints.down("md")]: {
      paddingBottom: 60,
    },
  },

  [`& .${classes.socialButtons}`]: {
    listStyle: "none",
    textAlign: "center",
    padding: 0,
    display: "flex",
    margin: 0,
  },

  [`& .${classes.socialButton}`]: {
    color: theme.palette.common.white,
  },

  [`& .${classes.disclaimer}`]: {
    color: theme.palette.common.white,
    textAlign: "center",
    lineHeight: "17px",
  },

  [`& .${classes.menu}`]: {
    color: theme.palette.common.white,
    textAlign: "center",
  },

  [`& .${classes.menuItem}`]: {
    color: theme.palette.common.white,

    "&:hover": {
      textDecoration: "underline",
    },
  },

  [`& .${classes.menuLinks}`]: {
    textAlign: "center",
  },

  [`& .${classes.logo}`]: {
    width: 292,
  },
}))

const Footer = () => {
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const data = useStaticQuery(query)

  const footerMenu = data.footerMenus.edges
    .filter(edge => edge.node.lang === lang.locale)
    .map(r => r.node)[0]

  const secondaryFooterMenu = data.secondaryFooterMenus.edges
    .filter(edge => edge.node.lang === lang.locale)
    .map(r => r.node)[0]

  return (
    <Root>
      <Container maxWidth={false} className={classes.root}>
        <Grid
          container
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <ul className={classes.socialButtons}>
              <li>
                <IconButton
                  component="a"
                  target="_blank"
                  href="https://www.facebook.com/CentreleAres/"
                  color="primary"
                  className={classes.socialButton}
                  size="large"
                >
                  <FacebookIcon />
                </IconButton>
              </li>
              <li>
                <IconButton
                  component="a"
                  target="_blank"
                  href="https://www.youtube.com/user/MNLCardiologie"
                  color="primary"
                  className={classes.socialButton}
                  size="large"
                >
                  <YouTubeIcon />
                </IconButton>
              </li>
              <li>
                <IconButton
                  component="a"
                  target="_blank"
                  href="https://www.linkedin.com/company/centrul-de-excelenta-in-cardiologie-si-radiologie-interventionala-med-new-life/"
                  color="primary"
                  className={classes.socialButton}
                  size="large"
                >
                  <LinkedInIcon />
                </IconButton>
              </li>
            </ul>
          </Grid>

          <Grid item xs={8}>
            <Typography
              xs={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                },
              }}
              variant="caption"
              component="p"
              className={classes.disclaimer}
            >
              {i18n.translations.shortPolicy}
            </Typography>
          </Grid>

          <Grid item xs={12} md={8} className={classes.menuLinks}>
            {footerMenu.data.links.map(link => (
              <span key={shortid.generate()}>
                <Typography
                  variant="caption"
                  component="a"
                  className={classes.menuItem}
                  href={`${process.env.GATSBY_BASE_URL}/${link.internal}`}
                >
                  {link.label}
                </Typography>
                &nbsp;&nbsp;
              </span>
            ))}
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography
              variant="caption"
              component="p"
              className={classes.menu}
            >
              Copyright &copy;{" "}
              <span style={{ fontWeight: 700 }}>MONZA ARES</span>
              &nbsp;
              {secondaryFooterMenu.data.links.map(link => (
                <span key={shortid.generate()}>
                  <Typography
                    variant="caption"
                    component="a"
                    className={classes.menuItem}
                    href={`${process.env.GATSBY_BASE_URL}/${link.internal}`}
                  >
                    {link.label}
                  </Typography>
                  &nbsp;&nbsp;
                </span>
              ))}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <img
              src={logoAresGroup}
              alt="MONZA ARES Group"
              width="292"
              height="40"
              className={classes.logo}
            />
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://anpc.ro/ce-este-sal/"
              title="Soluționarea Alternativă a Litigiilor"
              target="_blank"
            >
              <img
                src={logoSal}
                alt="Soluționarea Alternativă a Litigiilor"
                width="250"
                height="50"
                className={classes.logo}
                style={{
                  marginRight: 10,
                }}
              />
            </a>
            <a
              href="https://ec.europa.eu/consumers/odr"
              title="Soluționarea online a litigiilor"
              target="_blank"
            >
              <img
                src={logoSol}
                alt="Soluționarea online a litigiilor"
                width="250"
                height="50"
                className={classes.logo}
              />
            </a>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
          },
        }}
      >
        <ContactBar />
      </Box>
    </Root>
  )
}

export default Footer

const query = graphql`
  query FooterQuery {
    footerMenus: allPrismicFooterMenu {
      edges {
        node {
          lang
          id
          data {
            links {
              label
              internal
              url {
                link_type
                url
                target
              }
            }
          }
        }
      }
    }

    secondaryFooterMenus: allPrismicSecondaryFooterMenu {
      edges {
        node {
          lang
          id
          data {
            links {
              label
              internal
              url {
                link_type
                url
                target
              }
            }
          }
        }
      }
    }
  }
`
