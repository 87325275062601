import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import Dialog from "@mui/material/Dialog"
import DialogContentText from "@mui/material/DialogContentText"
import Typography from "@mui/material/Typography"
import fromUnixTime from "date-fns/fromUnixTime"
import getUnixTime from "date-fns/getUnixTime"
import isBefore from "date-fns/isBefore"
import isValid from "date-fns/isValid"
import subHours from "date-fns/subHours"
import { graphql, useStaticQuery } from "gatsby"
import React, { useContext, useState } from "react"
import "lazysizes"
import LangContext from "../contexts/LangContext"
import { Box, useMediaQuery, useTheme } from "@mui/material"

const GlobalAnnouncement = () => {
  const lang = useContext(LangContext)

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const data = useStaticQuery(query)

  const homepage = data.homepages.edges
    .filter(edge => edge.node.lang === lang.locale)
    .map(r => r.node.data)
    .pop()

  const [open, setOpen] = useState(() => {
    const lastTime = fromUnixTime(
      typeof window !== "undefined" &&
      window.localStorage.getItem("announcement-last-display"),
    )

    return !isValid(lastTime) || isBefore(lastTime, subHours(new Date(), 6))
  })

  const handleClose = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(
        "announcement-last-display",
        getUnixTime(new Date()),
      )
    }

    setOpen(false)
  }

  return (
    <>
      {homepage.announcement_active && (
        <Dialog
          elevation={5}
          fullWidth
          fullScreen={isSmDown}
          PaperProps={{ square: true }}
          sx={{ zIndex: 99999 }}
          maxWidth="md"
          open={open}
        >
          <Box
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              cursor: "pointer",
              color: theme => theme.palette.common.white,
            }}
            onClick={handleClose}
            onKeyDown={handleClose}
            role="button"
            tabIndex="0"
          >
            <CloseRoundedIcon />
          </Box>
          <Typography
            sx={{
              '& p': {
                margin: 0
              }
            }}
            component={DialogContentText}
            dangerouslySetInnerHTML={{
              __html: homepage.announcement_content.html,
            }}
          />
        </Dialog>
      )}
    </>
  )
}

export default GlobalAnnouncement

const query = graphql`
  query GlobalAnnouncementQuery {
    homepages: allPrismicHomepage {
      edges {
        node {
          lang
          data {
            announcement_active
            announcement_content {
              html
            }
            announcement_title {
              text
            }
          }
        }
      }
    }
  }
`

GlobalAnnouncement.propTypes = {}
