import CssBaseline from "@mui/material/CssBaseline"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import "../../styles/base.scss"
// Additional styles
import "../../styles/custom.css"
import "../../styles/homeMenu.scss"
import theme from "../../styles/theme"
import GlobalAnnouncement from "../GlobalAnnouncement"
import Footer from "./Footer"
import Header from "./Header"

const Layout = ({ children, pageContext, location }) => {
  const [contentScrollable] = useState(true)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    DruidWebchat_v2.init({
      botId: "0797c4f0-000d-456e-9358-08db005f097c",
      queryParams: "", // pass queryParams to AuthorizeAsync method
      baseUrl: "https://druidapi.druidplatform.com",
      UI: {
        autoExpandEnabled: true,
      },
    })
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <div className="mainContainer">{children}</div>
        <Footer style={{ position: contentScrollable ? "static" : "fixed" }} />
        <GlobalAnnouncement />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export { Layout }

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
