import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext } from "react"

import LangContext from "../../contexts/LangContext"
import "../../styles/search.scss"
import SearchResults from "./SearchResults"

const SearchDialog = ({ onClose }) => {
  const lang = useContext(LangContext)

  return (
    <StaticQuery
      query={graphql`
        query SearchIndexQuery {
          siteSearchIndex {
            index
          }
        }
      `}
      render={data => (
        <div className="searchWrapper">
          <IconButton
            color="inherit"
            onClick={onClose}
            edge="start"
            className="searchCloseBtn"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <SearchResults
            searchIndex={data.siteSearchIndex.index}
            lang={lang}
            onResultClick={onClose}
          />
        </div>
      )}
    />
  )
}

export default SearchDialog

SearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}
