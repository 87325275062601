import SearchIcon from "@mui/icons-material/Search"
import Container from "@mui/material/Container"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"
import { Index } from "elasticlunr"
import { Link } from "gatsby"
import React, { useState } from "react"
import * as shortid from "shortid"

const PREFIX = "SearchResults"

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  list: `${PREFIX}-list`,
  result: `${PREFIX}-result`,
  link: `${PREFIX}-link`,
}

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.wrapper}`]: {
    marginTop: 16,
    background: "#424041",
  },

  [`& .${classes.list}`]: {
    padding: 0,
    margin: 0,
    listStyle: "none",
    marginBottom: 16,
  },

  [`& .${classes.result}`]: {
    color: "#fff",
    cursor: "pointer",
    borderTop: "1px solid rgba(255, 255, 255, 0.3)",

    "&:last-child": {
      borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
    },
  },

  [`& .${classes.link}`]: {
    padding: "12px 16px",
    display: "block",
    color: "#fff",

    "&:hover": {
      background: "#065ea6",
      textDecoration: "none",
    },
  },
}))

const SearchResults = ({ searchIndex, onResultClick }) => {
  const [query, setQuery] = useState("")
  const [results, setResults] = useState([])
  const [index, setIndex] = useState()

  const getOrCreateIndex = () => index || Index.load(searchIndex)

  const onSearch = evt => {
    const q = evt.target.value
    const idx = getOrCreateIndex()
    const res = idx
      .search(evt.target.value, { expand: true })
      .map(({ ref }) => idx.documentStore.getDoc(ref))

    setIndex(idx)
    setQuery(q)
    setResults(res)
  }

  return (
    <StyledContainer maxWidth="sm">
      <TextField
        variant="outlined"
        type="text"
        label="Caută"
        fullWidth
        value={query}
        autoFocus
        onChange={onSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                color="primary"
                aria-label="toggle password visibility"
                size="large"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.wrapper}>
        <ul className={classes.list}>
          {results.map(page => (
            <li key={shortid.generate()} className={classes.result}>
              <Link
                onClick={() => {
                  onResultClick()
                }}
                to={page.slug}
                className={classes.link}
              >
                {page.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </StyledContainer>
  )
}

export default SearchResults
